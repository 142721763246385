<template>
  <div class="app-container">
    <div style="margin-bottom:10px;display: flex;justify-content: space-between;width: 100%;">
      <div>
        <el-input v-model="listQuery.title" size="small" placeholder="请输入文章标题" style="width:180px"></el-input>
        <el-select v-model="listQuery.type" size="small" placeholder="请选择文章类型" style="width:125px;" clearable>
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.type_name"
            :value="item.type_name">
          </el-option>
        </el-select>
        <el-select v-model="listQuery.status" size="small" placeholder="请选择发布状态" style="width:125px;" clearable>
          <el-option
            v-for="item in optionsType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button size="small" type="primary" @click="getList">
          <el-icon><Search /></el-icon> <span>搜索</span>
        </el-button>
        <el-button  size="small" type="primary" @click="cancel" style="margin-left: 5px!important;">
          <el-icon><RefreshRight /></el-icon> <span>重置</span>
        </el-button>
      </div>
      <div>
        <el-button type="primary" @click="add()" size="small" icon="Plus"
        >新增</el-button>
      </div>
    </div>

    <el-table :height="contentStyleObj" :data="list" border style="width: 100%">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="index" width="60" fixed label="序号"></el-table-column>
      <el-table-column prop="title" label="标题" fixed width="350" align="center">
      </el-table-column>
      <el-table-column prop="summary" label="简介" width="600" align="center">
      </el-table-column>
      <el-table-column prop="type" label="文章类型" width="150" align="center">
        <template #default="scope">
          <!-- <span>{{$filterWebsite(scope.row.type)}}</span> -->
        </template>
      </el-table-column>
      <el-table-column prop="status" label="发布状态" width="150" align="center">
        <template #default="scope">
          <!-- <span>{{$filterWebsite(scope.row.type)}}</span> -->
          <el-tag type="success" v-if="scope.row.status==1">已发布</el-tag>
          <el-tag type="info" v-else>未发布</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="topic_linkman" label="联系人" width="160" align="center">
      </el-table-column>

      <el-table-column  label="操作" align="center">
        <template #default="scope">
          <el-popconfirm
            confirm-button-text="发布"
            cancel-button-text="取消"
            :icon="InfoFilled"
            :title="'您确定要发布《'+scope.row.title+'》?'"
            @confirm="confirmEvent(scope.row)"
            @cancel="cancelEvent"
          >
            <template #reference>
              <el-button
                type="success"
                size="small"
                plain
                icon="Promotion"
              >发布</el-button>
            </template>
          </el-popconfirm>
          <el-button
            type="primary"
            size="small"
            @click="save(scope.row)"
            plain
            icon="Edit"
            >编辑</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="dels(scope.row)"
            plain
            icon="Delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>

    <el-dialog
      :close-on-click-modal="false"
      destroy-on-close
      title="发布官网行业资讯"
      v-model="dialogFormVisible"
    >
      <el-scrollbar :height="contentStyleObj">
        <el-form :model="form" :rules="rules" ref="form">
          <!-- <el-form-item label="是否发布" :label-width="formLabelWidth" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="发布日期" :label-width="formLabelWidth" prop="publish_date">
            <el-date-picker
              style="width:180px;margin-right: 10px;"
              size="small"
              v-model="form.publish_date"
              type="datetime"
              placeholder="请选择发布日期"
              >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="文章类型" :label-width="formLabelWidth" prop="type">
            <el-select v-model="form.type" size="small" placeholder="请选择文章类型" style="width:180px;margin-left: -1px;">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.type_name"
                :value="item.type_name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人" :label-width="formLabelWidth" :prop="form.type=='赋能特训营' ? 'topic_linkman':''">
            <el-input v-model="form.topic_linkman" size="small" style="width:96%"></el-input>
          </el-form-item>
          <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
            <el-input v-model="form.title" size="small" style="width:96%"></el-input>
          </el-form-item>
          <el-form-item label="简介" :label-width="formLabelWidth" prop="summary">
            <el-input v-model="form.summary" size="small" style="width:96%"></el-input>
          </el-form-item>
          <el-form-item label="封面图片" :label-width="formLabelWidth">
            <div>
              <div class="archives_up" v-if="form.image_url">
                <img
                  :src="form.image_url"
                  style="height: 80px; margin-right: 5px"
                  @click="checkImg(form.image_url)"
                />
                <a class="del_btn" @click="delImg()">x</a>
              </div>
              <div class="bigimg" style="margin-left: 0" v-if="!form.image_url">
                <el-button type="primary" size="small"
                  ><input
                    @change="uploadimg($event)"
                    class="inputfl"
                    type="file"
                    name="upFile"
                    id=""
                  />上传图片</el-button
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item label="视频" :label-width="formLabelWidth" v-if="form.type == '赋能特训营'">
            <div style="width: 360px;" v-if="!form.media_path">
              <el-input
                style="width: 50%; margin-right: 10px"
                v-model="path"
                size="small"
              ></el-input>
              <input
                size="small"
                ref="excel-upload-input"
                class="excel-upload-input"
                style="display: none"
                type="file"
                @change="handleClick"
              />
              <el-button type="primary" @click="handleUpload()" size="small"
                >请选择文件</el-button
              >
              <el-button
                size="small"
                type="primary"
                @click="uploadExcel"
                v-loading="loading"
                >上传</el-button
              >
            </div>
            <div v-else>
              <span style="text-decoration: underline;color: blue;cursor: pointer;" @click="openVideo(form.media_path)">{{ form.media_path }}</span>
              <span style="color: red;font-size: 18px;cursor: pointer;margin-left: 5px;" @click="delmedia">×</span>
            </div>
          </el-form-item>
          <el-form-item label="文章内容" :label-width="formLabelWidth" prop="content">
            <wangEditor v-model:html="form.content" style="width:96%"></wangEditor>
          </el-form-item>
        </el-form>
      </el-scrollbar>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" @click="submit" size="small"
            >确 定</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showImagePreview"
      :zoom-rate="1.2"
      @close="showImagePreview = false"
      :url-list="showImg"
    />
  </div>
</template>

<script>
import { getArticleList, getListArticleTypes, createArticle, updateArticle,deleteArticle } from "@/api/website.js";
import upload from "@/components/uploadImg/upload";
import { getAddress } from "@/api/company";

// import { vWangeditor } from 'vue3-wangeditor'
import wangEditor from "./components/wangEditor.vue";
import axios from "axios";
//
export default {
  name: "websiteInfomation",
  // components: { vWangeditor},
  components: { 
    upload,
    wangEditor
  },

  data() {
    return {
      dialogFormVisible: false,
      listQuery: {
        type:'',
        page: 1,
        limit: 20,
        status:9,
        title:'',
      },
      total: 0,
      list: [],
      listLoading: true,
      formLabelWidth: "120px",
      form: {
        status:0,
        type: '',
        title: "",
        summary: "",
        imageUrl: "",
        content: "",
        topic_linkman:'',
        image_url:'',
        media_path:'',
        publish_date:'',
      },
      editorOption: {},
      contentStyleObj: {}, //高度变化
      options:[],
      rules: {
        status: [
          { required: true, message: '请选择是否发布', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择文章类型', trigger: 'change' }
        ],
        publish_date: [
          { required: true, message: '请选择发布时间', trigger: 'change' }
        ],
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        summary: [
          { required: true, message: '请输入简介', trigger: 'blur' }
        ],
        imageUrl: [
          { required: true, message: '请输入封面图片链接', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入文章内容', trigger: 'blur' }
        ],
        topic_linkman: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
      },
      files:'',
      rawFile:'',
      path:'',
      showImagePreview: false,
      optionsType:[
        {
          label: '全部',
          value: 9
        },
        {
          label: '发布',
          value: 1
        },
        {
          label: '未发布',
          value: 0
        }
      ]
    };
  },
  created() {
    this.init();
    this.getList();
    // 获取表格高度
    this.contentStyleObj = this.$getHeight(220);
    window.addEventListener("resize", this.getHeight);
  },
  methods: {
    reset(){
      this.form = {
        status:0,
        type: '',
        title: "",
        summary: "",
        imageUrl: "",
        content: "",
        topic_linkman:'',
        image_url:'',
        media_path:'',
        publish_date:'',
      }
    },
    init() {
      getListArticleTypes({}).then((res) => {
        console.log(res);
        if (res.data.data) {
          this.options = res.data.data
        }
      });
    },
    dels(val) {
      deleteArticle(val).then((res) => {
        console.log(res);
        if (res.data.msg == "success") {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        }
      });
    },
    delmedia(){
      this.form.media_path = ''
    },
    openVideo(val){
      window.open(val)
    },
    save(row) {
      this.form = row
      this.dialogFormVisible = true
    },
    add() {
      this.form = {}
      this.form.content = "请输入...";
      this.dialogFormVisible = true;
    },
    changeContent(e) {
      this.form.content = e.html;
    },
    submit() {
      this.form.status =  0
      if(!this.form.image_url){
        this.$qzfMessage("请上传封面图片",1)
        return false
      }
      if(this.form.type == '赋能特训营' && !this.form.media_path){
        this.$qzfMessage("请上传视频",1)
        return false
      }
      if(this.form.id){
        this.$refs['form'].validate((valid) => {
          if (valid) {
           updateArticle(this.form).then((response) => {
            console.log(response);
            this.reset()
            this.files = ' '
            this.rawFile = ''
            this.path = ''
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.dialogFormVisible = false;
          });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }else{
        this.$refs['form'].validate((valid) => {
          if (valid) {
            createArticle(this.form).then((res) => {
              if (res.data.msg == "success") {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                this.getList();
                this.reset()
                this.files = ' '
                this.rawFile = ''
                this.path = ''
                this.dialogFormVisible = false;
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
      // this.form.publish_date = (new Date(this.form.publish_date)).getTime();
    },
    getList() {
      this.listLoading = true;
      getArticleList(this.listQuery).then((response) => {
        this.list = response.data.data.list;
        this.total = response.data.data.total;
        this.listLoading = false;
      });
    },
    cancel(){
      this.listQuery={
        type:'',
        page: 1,
        limit: 20,
        status:9,
        title:'',
      }
      this.getList();
    },
    // 发布操作
    confirmEvent(val){
      this.form = val
      this.form.status =  1
      updateArticle(this.form).then((response) => {
        this.reset()
        this.files = ' '
        this.rawFile = ''
        this.path = ''
        this.$message({
          message: "发布成功",
          type: "success",
        });
      });
    },
    //上传图片
    uploadimg(event) {
      console.log(event);
      if (event.target.files.length > 0) {
        this.files = event.target.files[0]; // 提交的图片
        this.addSub();
      }
    },
    addSub() {
      const files = this.files;
      const isLt1M = files.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message({
          message: "请不要上传大于1M的文件。",
          type: "warning",
        });
        return;
      }
      const param = new FormData(); // 创建form对象
      if (files !== "") {
        param.append("upFile", files, files.name); // 单个图片 ，多个用循环 append 添加
      } else {
        this.$message.error("请添加图片");
      }
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      axios
        .post("https://public.listensoft.net/api/uploadImg", param, config)
        .then((res) => {
          const imgs = res.data.data;
          this.form.image_url = "https://file.listensoft.net" + imgs.url
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除图片
    delImg() {
      this.form.image_url=''
    },
    checkImg(item) {
      console.log(item);
      let arr = [];
      arr.push(item);
      this.showImg = arr;
      console.log(this.showImg);
      this.showImagePreview = true;
    },
    //上传文件
    handleClick(e) {
      console.log(e);
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      const files = this.rawFile;
      const isLt1M = files.size / 1024 / 1024 < 100;
      if (!isLt1M) {
        this.$qzfMessage("请不要上传大于100M的文件。", 1);
        return;
      }
      const param = new FormData(); // 创建form对象
      if (this.rawFile != "") {
        param.append("upFile", this.rawFile, this.rawFile.name);
      } else {
        this.$message.error("请选择文件");
        return;
      }
      this.loading = true;
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      axios
        .post("https://public.listensoft.net/api/uploadImg", param, config)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.data.msg == "success") {
            this.$qzfMessage("上传成功");
            this.form.media_path =
              "https://file.listensoft.net" + res.data.data.url;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.archives_up {
  width: 96%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bigimg {
  margin-top: 19px;
}
.bigimg button {
  transform: translateY(-10px);
  margin-left: 20px;
  cursor: pointer;
}
.bigimg input {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.pic {
  display: flex;
}
.del_btn {
  position: relative;
  top: -97px;
  left: 94%;
  padding-right: 10px;
  color: red;
  cursor: pointer;
}
</style>
