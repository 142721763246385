import request from '@/utils/request'

// 获取文章列表
export function getArticleList(data) {
  return request({
    url: 'api/public/v1/getArticleList',
    method: 'post',
    data
  })
}
// 获取文章类型
export function getListArticleTypes(data) {
  return request({
    url: 'api/v1/getListArticleTypes',
    method: 'post',
    data
  })
}
// 新增文章
export function createArticle(data) {
  return request({
    url: 'api/v1/createArticle',
    method: 'post',
    data
  })
}
// 修改文章
export function updateArticle(data) {
  return request({
    url: 'api/v1/updateArticle',
    method: 'post',
    data
  })
}
// 删除文章
export function deleteArticle(data) {
  return request({
    url: 'api/v1/deleteArticle',
    method: 'post',
    data
  })
}
